import axios from 'axios'
import { getAuth } from '../../modules/auth/core/AuthHelpers'
import {CompanyListResponseModel, CompanyTypeListResponseModel, GroupListResponseModel, ReportPolicyModel, ReportPolicyResponseModel, ReportListResponseModel, TestUrlResponseModel, UserListResponseModel, UserLocationResponseModel, UserSessionResponseModel, UserPolicyResponseModel} from './_models'

const API_URL = window.env.API_URL

export const GET_REPORTPOLICIES_URL = `${API_URL}/reportpolicies`
export const GET_COMPANIES_URL = `${API_URL}/companies`
export const GET_COMPANYTYPES_URL = `${API_URL}/companytypes`
export const GET_GROUPS_URL = `${API_URL}/groups`
export const GET_REPORTS_URL = `${API_URL}/reports`
export const GET_USERS_URL = `${API_URL}/users`
export const GET_USERPOLICIES_URL = `${API_URL}/userpolicies`

export const POST_REPORTPOLICIES_URL = `${API_URL}/reportpolicies`
export const POST_REPORTS_URL = `${API_URL}/reports`

export function getReportPolicies() {
  return axios.get<ReportPolicyResponseModel>(GET_REPORTPOLICIES_URL, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getReportPolicy(id: string) {
  return axios.get<ReportPolicyModel>(GET_REPORTPOLICIES_URL + '/' + id, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getCompanies() {
  return axios.get<CompanyListResponseModel>(GET_COMPANIES_URL, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getCompanyTypes() {
  return axios.get<CompanyTypeListResponseModel>(GET_COMPANYTYPES_URL, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getGroups() {
  return axios.get<GroupListResponseModel>(GET_GROUPS_URL, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function createPolicy(name: string, companyID: number, groupID: number) {
  return axios.post<ReportPolicyModel>(POST_REPORTPOLICIES_URL, {
    name, companyID, groupID
  }, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getReports() {
  return axios.get<ReportListResponseModel>(GET_REPORTS_URL, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getUnmanagedReports() {
  return axios.get<ReportListResponseModel>(GET_REPORTS_URL + '/unmanaged', {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function associateReports(policyId: string, reportIds: string[]) {
  return axios.post(`${POST_REPORTPOLICIES_URL}/${policyId}/reports/associate`, {
    reportIds
  }, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function dissociateReports(policyId: string, reportIds: string[]) {
  return axios.post(`${POST_REPORTPOLICIES_URL}/${policyId}/reports/dissociate`, {
    reportIds
  }, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function associateUsers(policyId: string, userIds: number[]) {
  return axios.post(`${POST_REPORTPOLICIES_URL}/${policyId}/users/associate`, {
    userIds
  }, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function dissociateUsers(policyId: string, userIds: number[]) {
  return axios.post(`${POST_REPORTPOLICIES_URL}/${policyId}/users/dissociate`, {
    userIds
  }, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function deletePolicy(id: string) {
  return axios.delete(POST_REPORTPOLICIES_URL + '/' + id, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getUsers(){
  return axios.get<UserListResponseModel>(GET_USERS_URL, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function manageReports(reportIds: string[]) {
  return axios.post<ReportListResponseModel>(`${POST_REPORTS_URL}/manage`, {
    reportIds
  }, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function unmanageReports(reportIds: string[]) {
  return axios.post<ReportListResponseModel>(`${POST_REPORTS_URL}/unmanage`, {
    reportIds
  }, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getUserSessionID(userId: number) {
  return axios.get<UserSessionResponseModel>(`${GET_USERS_URL}/${userId}/session`, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getUserLocations(userId: number) {
  return axios.get<UserLocationResponseModel>(`${GET_USERS_URL}/${userId}/locations`, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getTestUrl(sessionID: string, locationID: number) {
  return axios.get<TestUrlResponseModel>(`${API_URL}/test/url?SessionID=${sessionID}&LocationID=${locationID}`, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}

export function getUserPolicies(){
  return axios.get<UserPolicyResponseModel>(GET_USERPOLICIES_URL, {
    headers: { Authorization: `Bearer ${getAuth()?.authToken}` }
  })
}