import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { Outlet, useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import { ReportModel } from '../core/_models'
import { getReports, getUnmanagedReports, manageReports, unmanageReports } from '../core/_requests'
import { CreateToast, ToastTypes } from '../../plugins/Toasts'
import axios, { AxiosError } from 'axios'

const ReportAdminPage: React.FC = () => {
  const [ managedReports, setManagedReports ] = useState<ReportModel[]>([])
  const [ serverReports, setServerReports ] = useState<ReportModel[]>([])

  const [ filterServerReportsString, setFilterServerReportsString ] = useState('')
  const [ filterManagedReportsString, setFilterManagedReportsString ] = useState('')

  const [ selectedServerReports, setSelectedServerReports ] = useState<Array<string>>([])
  const [ selectedManagedReports, setSelectedManagedReports ] = useState<Array<string>>([])

  const [ pendingManaged, setPendingManaged ] = useState<Array<string>>([])
  const [ pendingUnmanage, setPendingUnmanage ] = useState<Array<string>>([])

  const [ loadingManagedReports, setLoadingManagedReports ] = useState(false)
  const [ loadingServerReports, setLoadingServerReports ] = useState(false)

  useEffect(() => {
    loadManagedReports()
    loadServerReports()
  }, [])

  const loadManagedReports = () => {
    setLoadingManagedReports(true)
    
    getReports().then(response => {
      setManagedReports(response.data.reports)
    }).catch(error => {
      let message

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = axiosError.response.data.message
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }
      
      CreateToast(message, 'Error', ToastTypes.Danger)
    }).finally(() => {
      setLoadingManagedReports(false)
    })
  }

  const loadServerReports = () => {
    setLoadingServerReports(true)
    
    getUnmanagedReports().then(response => {
      setServerReports(response.data.reports)
    }).catch(error => {
      let message

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = axiosError.response.data.message
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }
      
      CreateToast(message, 'Error', ToastTypes.Danger)
    }).finally(() => {
      setLoadingServerReports(false)
    })
  }

  const updateSelected = (e: ChangeEvent<HTMLInputElement>, list: Array<string>, setList: React.Dispatch<React.SetStateAction<string[]>>, id: string) => {
    if (!e.target) { return }

    if (e.target.checked) {
      setList([...list, id])
    } else {
      setList(list.filter(x => x !== id))
    }
  }

  const manageSelectedReports = () => {
    setPendingManaged([...pendingManaged, ...selectedServerReports.filter(x => !managedReports.find(y => y.id === x))])
    setPendingUnmanage([...pendingUnmanage.filter(x => !selectedServerReports.find(y => x === y))])
    setSelectedServerReports([])
  }

  const unmanageSelectedReports = () => {
    setPendingUnmanage([...pendingUnmanage, ...selectedManagedReports.filter(x => !!managedReports.find(y => y.id === x))])
    setPendingManaged([...pendingManaged.filter(x => !selectedManagedReports.find(y => x === y))])
    setSelectedManagedReports([])
  }

  const filterUnmanaged = (report: ReportModel) => {
    if (!report) return false
    if (report.name.toLowerCase().indexOf(filterServerReportsString.toLowerCase()) === -1 && report.path.toLowerCase().indexOf(filterServerReportsString.toLowerCase()) === -1) return false
    if (!!managedReports.find(x => x.id === report.id) && !pendingUnmanage.find(x => report.id === x)) return false
    if (!!pendingManaged.find(x => report.id === x)) return false
    return true
  }

  const filterManaged = (report: ReportModel) => {
    if (!report) return false
    if (report.name.toLowerCase().indexOf(filterManagedReportsString.toLowerCase()) === -1 && report.path.toLowerCase().indexOf(filterManagedReportsString.toLowerCase()) === -1) return false
    if (!managedReports.find(x => x.id === report.id) && !pendingManaged.find(x => report.id === x)) return false
    if (!!pendingUnmanage.find(x => report.id === x)) return false
    return true
  }

  const savePending = async () => {
    let newManagedReports: ReportModel[] = []
    let newUnmanagedReports: ReportModel[] = []

    if (pendingManaged.length > 0) {
      setLoadingManagedReports(true)

      await manageReports(pendingManaged).then(async response => {
        // Get list of successfully managed reports from response
        let reports = response.data.reports

        // Remove successfully managed reports from pending managed
        let newPendingManaged = pendingManaged.filter(x => !reports.find(report => report.id === x))
        setPendingManaged(newPendingManaged)

        // Add reports to managed report list
        // setManagedReports([...managedReports, ...reports])
        newManagedReports.push(...reports)

        if (newPendingManaged.length > 0) {
          CreateToast(`${reports.length} reports managed, ${pendingManaged.length} failed to be managed`, 'Managed Reports', ToastTypes.Warning)
        } else {
          CreateToast('All reports managed successfully', 'Manage Reports', ToastTypes.Success)
        }
      }).catch(error => {
        let message
  
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError = error
  
          if (axiosError.response) {
            message = axiosError.response.data.message
          } else {
            message = 'The server could not be reached'
          }
        } else {
          message = 'An unknown error occurred'
        }
        
        CreateToast(message, 'Error', ToastTypes.Danger)
      }).finally(() => {
        setLoadingManagedReports(false)
      })
    }
    
    if (pendingUnmanage.length > 0) {
      setLoadingServerReports(true)

      await unmanageReports(pendingUnmanage).then(async response => {
        // Get list of successfully managed reports from response
        let reports = response.data.reports

        // Remove successfully managed reports from pending managed
        let newPendingUnmanaged = pendingUnmanage.filter(x => !reports.find(report => report.id === x))
        setPendingUnmanage(newPendingUnmanaged)
        
        // Remove reports from managed report list
        // setManagedReports(managedReports.filter(mr => !reports.find(r => r.id === mr.id)))
        newUnmanagedReports.push(...reports)
        
        if (newPendingUnmanaged.length > 0) {
          CreateToast(`${reports.length} reports unmanaged, ${pendingUnmanage.length} failed to be unmanaged`, 'Unmanaged Reports', ToastTypes.Warning)
        } else {
          CreateToast('All reports unmanaged successfully', 'Unmanage Reports', ToastTypes.Success)
        }
      }).catch(error => {
        let message
  
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError = error
  
          if (axiosError.response) {
            message = axiosError.response.data.message
          } else {
            message = 'The server could not be reached'
          }
        } else {
          message = 'An unknown error occurred'
        }
        
        CreateToast(message, 'Error', ToastTypes.Danger)
      }).finally(() => {
        setLoadingServerReports(false)
      })
    }

    setManagedReports([...managedReports.filter(r => !newUnmanagedReports.find(ur => ur.id === r.id)), ...newManagedReports])
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Report Admin</PageTitle>

      <div className='card'>
        <div className='card-body'>
          <div className='row'>
            {/* BEGIN SERVER REPORTS */}
            <div className='col-12 col-lg-5'>
              <div className='row'>
                <div className='col-12 text-center mb-1'>
                  <span className='h5'>Server Reports</span>
                </div>

                <div className='col pe-0'>
                  <Form.Control
                    type='text'
                    placeholder='Search'
                    value={filterServerReportsString}
                    onChange={e => setFilterServerReportsString(e.target.value)}
                  />
                </div>
                
                <div className='col-auto'>
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn-icon h-100"
                    disabled={filterServerReportsString === ''}
                    onClick={e => setFilterServerReportsString('')}>
                    <i className="bi bi-x-lg fs-4"></i>
                  </Button>
                </div>
              </div>

              {/* List */}
              <div className='mh-200px mh-lg-400px overflow-scroll mt-2'>
                {
                  serverReports?.filter(filterUnmanaged).map(report => (
                    <div className="form-check pt-1" key={report.id}>
                      <input
                        className="form-check-input h-20px w-20px"
                        type="checkbox"
                        value=""
                        checked={!!selectedServerReports.find(r => r === report.id)}
                        id={"report_unmanaged_check_" + report.id}
                        onChange={e => updateSelected(e, selectedServerReports, setSelectedServerReports, report.id)}/>
                      <label className={`form-check-label mw-100 text-break ${pendingUnmanage.find(x => x === report.id) ? 'bg-danger bg-gradient rounded px-2' : ''}`} htmlFor={"report_unmanaged_check_" + report.id}>
                        { report.name }<br/><span className={`${pendingUnmanage.find(x => x === report.id) ? '' : 'text-muted'} fs-8`}>{ report.path }</span>
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>
            {/* END SERVER REPORTS */}

            {/* BEGIN MANAGE BUTTONS */}
            <div className='col-12 col-lg-2 align-items-center'>
              <div className='d-flex flex-column justify-content-center h-100'>
                <Button
                  type="button"
                  variant="primary"
                  size="sm"
                  className="my-1"
                  disabled={selectedServerReports.length === 0}
                  onClick={e => { manageSelectedReports() }}>
                  Manage Selected
                </Button>

                <Button
                  type="button"
                  variant="primary"
                  size="sm"
                  className="my-1 d-none d-lg-block"
                  disabled={selectedManagedReports.length === 0}
                  onClick={e => { unmanageSelectedReports() }}>
                  Unmanage Selected
                </Button>
              </div>
            </div>
            {/* END MANAGE BUTTONS */}

            {/* BEING MANAGED REPORTS */}
            <div className='col-12 col-lg-5 mt-3 mt-lg-0'>
              <div className='row'>
                <div className='col-12 text-center mb-1'>
                  <span className='h5'>Managed Reports</span>
                </div>

                <div className='col pe-0'>
                  <Form.Control
                    type='text'
                    placeholder='Search'
                    value={filterManagedReportsString}
                    onChange={e => setFilterManagedReportsString(e.target.value)}
                  />
                </div>
                
                <div className='col-auto'>
                  <Button
                    type="button"
                    variant="secondary"
                    className="btn-icon h-100"
                    disabled={filterManagedReportsString === ''}
                    onClick={e => setFilterManagedReportsString('')}>
                    <i className="bi bi-x-lg fs-4"></i>
                  </Button>
                </div>
              </div>

              {/* List */}
              <div className='mh-200px mh-lg-400px overflow-scroll mt-2'>
                {
                  serverReports?.filter(filterManaged).map(report => (
                    <div className="form-check pt-1" key={report.id}>
                      <input
                        className="form-check-input h-20px w-20px"
                        type="checkbox"
                        value=""
                        checked={!!selectedManagedReports.find(r => r === report.id)}
                        id={"report_managed_check_" + report.id}
                        onChange={e => updateSelected(e, selectedManagedReports, setSelectedManagedReports, report.id)}/>
                      <label className={`form-check-label mw-100 text-break ${pendingManaged.find(x => x === report.id) ? 'bg-success bg-gradient rounded px-2' : ''}`} htmlFor={"report_managed_check_" + report.id}>
                        { report.name }<br/><span className={`${pendingManaged.find(x => x === report.id) ? '' : 'text-muted'} fs-8`}>{ report.path }</span>
                      </label>
                    </div>
                  ))
                }
              </div>
            </div>
            {/* END MANAGED REPORTS */}

            {/* BEGIN DISSASOCIATE BUTTON */}
            <div className='col-12 d-lg-none align-items-center'>
              <div className='d-flex flex-column justify-content-center h-100'>
                <Button
                  type="button"
                  variant="primary"
                  size="sm"
                  className="my-1"
                  disabled={selectedManagedReports.length === 0}
                  onClick={e => { unmanageSelectedReports() }}>
                  Unmanage Selected
                </Button>
              </div>
            </div>
            {/* END DISSASOCIATE BUTTON */}
          </div>

          <div className='row mt-4 justify-content-end'>
            <div className='col-auto'>
              <Button
                type="button"
                variant="secondary"
                className='me-2'
                disabled={[...pendingManaged, ...pendingUnmanage].length === 0}
                onClick={() => { setPendingManaged([]); setPendingUnmanage([]); setSelectedManagedReports([]); setSelectedServerReports([]) }}>
                Discard
              </Button>

              <Button
                type="button"
                variant="primary"
                disabled={[...pendingManaged, ...pendingUnmanage].length === 0}
                onClick={() => savePending()}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { ReportAdminPage }