import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import { ReportPolicyModel, ReportModel } from '../core/_models'
import { Button, Modal } from 'react-bootstrap'
import { associateReports, dissociateReports, getReportPolicy, getReports } from '../core/_requests'
import { CreateToast, ToastTypes } from '../../plugins/Toasts'
import axios, { AxiosError } from 'axios'

const ManageReportModal: FC = () => {
  const navigate = useNavigate()
  const match = useMatch('/policy-admin/:id/reports')

  const [ visible, setVisible ] = useState(true)
  const [ policy, setPolicy ] = useState<ReportPolicyModel | null>(null)
  const [ reports, setReports ] = useState<Array<ReportModel> | null>(null)
  const [ isLoading, setLoading ] = useState(false)
  const [ modalTransitionCompleted, setTransitionCompleted ] = useState(false)
  const [ error, setError ] = useState(false)

  const [ filterAvailableString, setFilterAvailableString ] = useState('')
  const [ filterAssociatedString, setFilterAssociatedString ] = useState('')

  const [ selectedAvailableReports, setSelectedAvailableReports ] = useState<Array<string>>([])
  const [ selectedAssociatedReports, setSelectedAssociatedReports ] = useState<Array<string>>([])

  const [ pendingAssociation, setPendingAssociation ] = useState<Array<string>>([])
  const [ pendingDissociation, setPendingDissociation ] = useState<Array<string>>([])

  const modalShow = async () => {
    // No error checking here since this component is conditionally rendered anyway, so will always match. We simply check to satisfy TypeScript.
    if (!match || !match.params.id) { return }

    setTimeout(() => {
      setTransitionCompleted(true)
    }, 300)

    await getData()
  }

  const getData = async () => {
    if (!match || !match.params.id) { return }

    setLoading(true)

    const policyPromise = getReportPolicy(match.params.id)
    const reportPromise = getReports()

    await Promise.all([ policyPromise, reportPromise ]).then(([ policyResponse, reportResponse ]) => {
      setPolicy(policyResponse.data)
      setReports(reportResponse.data.reports)
    }).catch(error => {
      let message

      setLoading(false)
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = 'Policy not found'
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }

      modalHide()
      CreateToast(message, 'Error', ToastTypes.Danger)
    }).finally(() => {
      setLoading(false)
    })
  }

  const modalHide = () => {
    setVisible(false)

    // Delay route change so we see the modal close animation
    setTimeout(() => {
      navigate('/policy-admin', { state: 'noscroll' })
    }, 150)
  }

  const updateSelected = (e: ChangeEvent<HTMLInputElement>, list: Array<string>, setList: React.Dispatch<React.SetStateAction<string[]>>, id: string) => {
    if (!e.target) { return }

    if (e.target.checked) {
      setList([...list, id])
    } else {
      setList(list.filter(x => x !== id))
    }
  }

  const associateSelectedReports = () => {
    if (!policy || !policy.reportIds) { return }

    // policy.reportIds = [...policy.reportIds, ...selectedAvailableReports]
    // setPolicy(policy)

    setPendingAssociation([...pendingAssociation, ...selectedAvailableReports.filter(x => policy.reportIds.indexOf(x) === -1)])
    setPendingDissociation([...pendingDissociation.filter(x => !selectedAvailableReports.find(y => x === y))])
    setSelectedAvailableReports([])
  }

  const disassociateSelectedReports = () => {
    if (!policy || !policy.reportIds) { return }
    
    // policy.reportIds = [...policy.reportIds.filter(x => selectedAssociatedReports.indexOf(x) === -1)]
    // setPolicy(policy)

    setPendingDissociation([...pendingDissociation, ...selectedAssociatedReports.filter(x => policy.reportIds.indexOf(x) > -1)])
    setPendingAssociation([...pendingAssociation.filter(x => !selectedAssociatedReports.find(y => x === y))])
    setSelectedAssociatedReports([])
  }

  const filterAvailable = (report: ReportModel) => {
    if (!policy || !reports) return false
    if (report.name.toLowerCase().indexOf(filterAvailableString.toLowerCase()) === -1) return false
    if (policy.reportIds.indexOf(report.id) > -1 && pendingDissociation.indexOf(report.id) === -1) return false
    if (pendingAssociation.indexOf(report.id) > -1) return false
    return true
  }

  const filterAssociated = (report: ReportModel) => {
    if (!policy || !reports) return false
    if (report.name.toLowerCase().indexOf(filterAssociatedString.toLowerCase()) === -1) return false
    if (policy.reportIds.indexOf(report.id) === -1 && pendingAssociation.indexOf(report.id) === -1) return false
    if (pendingDissociation.indexOf(report.id) > -1) return false
    return true
  }

  const savePending = async () => {
    if (!policy) return

    setLoading(true)
    if (pendingDissociation.length > 0) await dissociateReports(policy.id, pendingDissociation)
    if (pendingAssociation.length > 0) await associateReports(policy.id, pendingAssociation)

    CreateToast('Associations saved successfully', 'Saved Associations', ToastTypes.Success)

    setLoading(false)
    modalHide()
  }

  return (
    <Modal size='lg' show={visible} onHide={modalHide} onShow={modalShow} className={ isLoading || !modalTransitionCompleted ? "overlay overlay-block position-fixed" : "" }>
      <Modal.Header closeButton>
        <Modal.Title>Report Management for Policy: { policy?.name }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          {/* BEGIN AVAILABLE REPORTS */}
          <div className='col-12 col-lg-5'>
            <div className='row'>
              {/* Header */}
              <div className='col-12 text-center mb-1'>
                <span className='h5'>Available Reports</span>
              </div>

              {/* Search Input */}
              <div className='col pe-0'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterAvailableString}
                  onChange={e => setFilterAvailableString(e.target.value)}
                />
              </div>

              {/* Clear Search Button */}
              <div className='col-auto'>
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-icon h-100"
                  disabled={filterAvailableString === ''}
                  onClick={e => setFilterAvailableString('')}>
                  <i className="bi bi-x-lg fs-4"></i>
                </Button>
              </div>
            </div>

            {/* Available Reports List */}
            <div className='mh-200px mh-lg-400px overflow-scroll mt-2'>
              {
                reports?.filter(filterAvailable).map(report => {
                  return (
                    <div className="form-check pt-1" key={report.id}>
                      <input
                        className="form-check-input h-20px w-20px"
                        type="checkbox"
                        value=""
                        checked={!!selectedAvailableReports.find(r => r === report.id)}
                        id={"report_available_check_" + report.id}
                        onChange={e => updateSelected(e, selectedAvailableReports, setSelectedAvailableReports, report.id)}/>
                      <label className={`form-check-label mw-100 text-break ${pendingDissociation.find(x => x === report.id) ? 'bg-danger bg-gradient rounded px-2' : ''}`} htmlFor={"report_available_check_" + report.id}>
                        { report.name }<br/><span className={`${pendingDissociation.find(x => x === report.id) ? '' : 'text-muted'} fs-8`}>{ report.category || '<No Category>' }</span>
                      </label>
                    </div>
                  )
                })
              }
            </div>
          </div>
          {/* END AVAILABLE REPORTS */}

          {/* BEGIN MANAGE BUTTONS */}
          <div className='col-12 col-lg-2 align-items-center'>
            <div className='d-flex flex-column justify-content-center h-100'>
              <Button
                type="button"
                variant="primary"
                size="sm"
                className="my-1"
                disabled={selectedAvailableReports.length === 0}
                onClick={e => { associateSelectedReports() }}>
                Associate Selected
              </Button>

              <Button
                type="button"
                variant="primary"
                size="sm"
                className="my-1 d-none d-lg-block"
                disabled={selectedAssociatedReports.length === 0}
                onClick={e => { disassociateSelectedReports() }}>
                Dissociate Selected
              </Button>
            </div>
          </div>
          {/* END MANAGE BUTTONS */}

          {/* BEGIN ASSOCIATED REPORTS */}
          <div className='col-12 col-lg-5 mt-3 mt-lg-0'>
            <div className='row'>
              {/* Header */}
              <div className='col-12 text-center mb-1'>
                <span className='h5'>Associated Reports</span>
              </div>

              {/* Search Input */}
              <div className='col pe-0'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterAssociatedString}
                  onChange={e => setFilterAssociatedString(e.target.value)}
                />
              </div>

              {/* Clear Search Button */}
              <div className='col-auto'>
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-icon h-100"
                  disabled={filterAssociatedString === ''}
                  onClick={e => setFilterAssociatedString('')}>
                  <i className="bi bi-x-lg fs-4"></i>
                </Button>
              </div>
            </div>

            {/* List */}
            <div className='mh-200px mh-lg-400px overflow-scroll mt-2'>
              {
                reports?.filter(filterAssociated).map(report => (
                  <div className="form-check pt-1" key={report.id}>
                    <input
                      className="form-check-input h-20px w-20px"
                      type="checkbox"
                      value=""
                      checked={!!selectedAssociatedReports.find(r => r === report.id)}
                      id={"report_associated_check_" + report.id}
                      onChange={e => updateSelected(e, selectedAssociatedReports, setSelectedAssociatedReports, report.id)}/>
                    <label className={`form-check-label mw-100 text-break ${pendingAssociation.find(x => x === report.id) ? 'bg-success bg-gradient rounded px-2' : ''}`} htmlFor={"report_associated_check_" + report.id}>
                      { report.name }<br/><span className={`${pendingAssociation.find(x => x === report.id) ? '' : 'text-muted'} fs-8`}>{ report.category || '<No Category>' }</span>
                    </label>
                  </div>
                ))
              }
            </div>
          </div>
          {/* BEGIN ASSOCIATED REPORTS */}

          {/* BEGIN DISSASOCIATE BUTTON */}
          <div className='col-12 d-lg-none align-items-center'>
            <div className='d-flex flex-column justify-content-center h-100'>
              <Button
                type="button"
                variant="primary"
                size="sm"
                className="my-1"
                disabled={selectedAssociatedReports.length === 0}
                onClick={e => { disassociateSelectedReports() }}>
                Dissociate Selected
              </Button>
            </div>
          </div>
          {/* END DISSASOCIATE BUTTON */}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="light"
          data-bs-dismiss="modal"
          onClick={modalHide}>
          Close
        </Button>
        <Button
          type="button"
          variant="primary"
          data-bs-dismiss="modal"
          disabled={[...pendingAssociation, ...pendingDissociation].length === 0}
          onClick={() => savePending()}>
          Save
        </Button>
      </Modal.Footer>
      {
        (isLoading || !modalTransitionCompleted) &&
        <div className="overlay-layer rounded bg-dark bg-opacity-50">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </Modal>
  )
}

export { ManageReportModal }