import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../_metronic/layout/core'
import { PolicyAdminPage } from './PolicyAdminPage'
import { ManageReportModal } from './ManageReportModal'
import { DeletePolicyModal } from './DeletePolicyModal'
import { ManageUsersModal } from './ManageUsersModal'

const PolicyAdminPageWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>Policy Admin</PageTitle>
    <Routes>
      <Route path='/' element={<PolicyAdminPage/>}>
        <Route path=":id/reports" element={ <ManageReportModal/> }/>
        <Route path=":id/users" element={ <ManageUsersModal/> }/>
        <Route path=":id/delete" element={ <DeletePolicyModal/> }/>
      </Route>
    </Routes>
  </>
)

export { PolicyAdminPageWrapper }
