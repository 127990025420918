import React, { ChangeEvent, FC,  useState } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import { associateUsers, dissociateUsers, getReportPolicy, getUserPolicies, getUsers } from '../core/_requests'
import { CreateToast, ToastTypes } from '../../plugins/Toasts'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { ReportPolicyModel, UserModel, UserPolicyModel } from '../core/_models'
import axios, { AxiosError } from 'axios'

interface IAvailableFilter {
  id: number,
  label: string
}

const availableFilters: IAvailableFilter[] = [
  { id: 1, label: 'All Users' },
  { id: 2, label: 'Company Users' },
  { id: 3, label: 'Policy Users' }
]

const ManageUsersModal: FC = () => {
  const navigate = useNavigate()
  const match = useMatch('/policy-admin/:id/users')

  const [ visible, setVisible ] = useState(true)
  const [ modalTransitionCompleted, setTransitionCompleted ] = useState(false)
  const [ isLoading, setLoading ] = useState(false)
  
  const [ policy, setPolicy ] = useState<ReportPolicyModel | null>(null)
  const [ users, setUsers ] = useState<UserModel[]>([])
  const [ userPolicies, setUserPolicies ] = useState<UserPolicyModel[]>([])

  const [ availableFilter, setAvailableFilter ] = useState(availableFilters[0].id)
  const [ filterAvailableString, setFilterAvailableString ] = useState('')
  const [ filterAssociatedString, setFilterAssociatedString ] = useState('')

  const [ selectedAvailableUsers, setSelectedAvailableUsers ] = useState<Array<number>>([])
  const [ selectedAssociatedUsers, setSelectedAssociatedUsers ] = useState<Array<number>>([])

  const [ pendingAssociation, setPendingAssociation ] = useState<Array<number>>([])
  const [ pendingDissociation, setPendingDissociation ] = useState<Array<number>>([])

  const modalShow = async () => {
    // No error checking here since this component is conditionally rendered anyway, so will always match. We simply check to satisfy TypeScript.
    if (!match || !match.params.id) { return }

    setTimeout(() => {
      setTransitionCompleted(true)
    }, 300)

    await getData()
  }
  
  const modalHide = () => {
    setVisible(false)

    // Delay route change so we see the modal close animation
    setTimeout(() => {
      navigate('/policy-admin', { state: 'noscroll' })
    }, 150)
  }

  const getData = async () => {
    if (!match || !match.params.id) { return }
    setLoading(true)

    const policyPromise = getReportPolicy(match.params.id)
    const userPromise = getUsers()
    const userPolicyPromise = getUserPolicies()

    await Promise.all([ policyPromise, userPromise, userPolicyPromise ]).then(([ policyResponse, userResponse, userPolicyResponse ]) => {
      setPolicy(policyResponse.data)
      setUsers(userResponse.data.users)
      setUserPolicies(userPolicyResponse.data.userPolicies)
    }).catch(error => {
      let message

      setLoading(false)
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = 'Policy not found'
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }

      modalHide()
      CreateToast(message, 'Error', ToastTypes.Danger)
    }).finally(() => {
      setLoading(false)
    })
  }

  const updateSelected = (e: ChangeEvent<HTMLInputElement>, list: Array<number>, setList: React.Dispatch<React.SetStateAction<number[]>>, id: number) => {
    if (!e.target) { return }

    if (e.target.checked) {
      setList([...list, id])
    } else {
      setList(list.filter(x => x !== id))
    }
  }

  const associateSelectedUsers = () => {
    if (!policy || !policy.userIds) { return }

    setPendingAssociation([...pendingAssociation, ...selectedAvailableUsers.filter(x => policy.userIds.indexOf(x) === -1)])
    setPendingDissociation([...pendingDissociation.filter(x => !selectedAvailableUsers.find(y => x === y))])
    setSelectedAvailableUsers([])
  }

  const disassociateSelectedUsers = () => {
    if (!policy || !policy.userIds) { return }

    setPendingDissociation([...pendingDissociation, ...selectedAssociatedUsers.filter(x => policy.userIds.indexOf(x) > -1)])
    setPendingAssociation([...pendingAssociation.filter(x => !selectedAssociatedUsers.find(y => x === y))])
    setSelectedAssociatedUsers([])
  }

  const filterAvailable = (user: UserModel) => {
    if (!policy || !user) return false
    
    if (availableFilter === 2 || availableFilter === 3) {
      if (policy.companyID !== user.companyID) return false

      if (availableFilter === 3) {
        const currentUserPolicies = userPolicies.filter(p => p.userID === user.id)
        if (!currentUserPolicies.some(p => p.groupID === policy.groupID)) return false
      }
    }

    if (user.fullname.toLowerCase().indexOf(filterAvailableString.toLowerCase()) === -1 && user.username.toLowerCase().indexOf(filterAvailableString.toLowerCase()) === -1) return false
    if (policy.userIds.indexOf(user.id) > -1 && pendingDissociation.indexOf(user.id) === -1) return false
    if (pendingAssociation.indexOf(user.id) > -1) return false
    return true
  }

  const filterAssociated = (user: UserModel) => {
    if (!policy || !user) return false
    if (user.fullname.toLowerCase().indexOf(filterAssociatedString.toLowerCase()) === -1 && user.username.toLowerCase().indexOf(filterAssociatedString.toLowerCase()) === -1) return false
    if (policy.userIds.indexOf(user.id) === -1 && pendingAssociation.indexOf(user.id) === -1) return false
    if (pendingDissociation.indexOf(user.id) > -1) return false
    return true
  }

  const savePending = async () => {
    if (!policy) return

    setLoading(true)
    if (pendingDissociation.length > 0) await dissociateUsers(policy.id, pendingDissociation)
    if (pendingAssociation.length > 0) await associateUsers(policy.id, pendingAssociation)

    CreateToast('Associations saved successfully', 'Saved Associations', ToastTypes.Success)

    setLoading(false)
    modalHide()
  }
  
  return (
    <Modal size='lg' show={visible} onHide={modalHide} onShow={modalShow} className={ isLoading || !modalTransitionCompleted ? "overlay overlay-block position-fixed" : "" }>
      <Modal.Header closeButton>
        <Modal.Title>User Management for Policy: { policy?.name }</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <div className='row'>
          {/* BEGIN AVAILABLE USERS */}
          <div className='col-12 col-lg-5'>
            <div className='row'>
              {/* Header */}
              <div className='col-12 text-center mb-1'>
                <span className='h5'>{ availableFilters.find(f => f.id === availableFilter)?.label }</span>
                <Dropdown style={{width: '0', float: 'right'}}>
                  <Dropdown.Toggle variant='light' className='btn-icon' style={{height: '21px', marginLeft: 'calc(-1 * (1.5em + 1.55rem + 2px))'}}>
                    <i className='bi bi-funnel-fill fs-3 btn-sm' ref={(node) => { if (node) { node.style.setProperty('font-size', '1rem', 'important') } }}></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {availableFilters.map(filter =>
                      <Dropdown.Item
                        key={filter.id}
                        onClick={() => {setAvailableFilter(filter.id)}}
                        active={availableFilter === filter.id}
                      >
                        {filter.label}
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* Search Input */}
              <div className='col pe-0'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterAvailableString}
                  onChange={e => setFilterAvailableString(e.target.value)}
                />
              </div>

              {/* Clear Search Button */}
              <div className='col-auto'>
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-icon h-100"
                  disabled={filterAvailableString === ''}
                  onClick={e => setFilterAvailableString('')}>
                  <i className="bi bi-x-lg fs-4"></i>
                </Button>
              </div>
            </div>

            {/* Available Users List */}
            <div className='mh-200px mh-lg-400px overflow-scroll mt-2'>
              {
                users?.filter(filterAvailable).slice(0, 100).map(user => {
                  return (
                    <div className="form-check pt-1" key={user.id}>
                      <input
                        className="form-check-input h-20px w-20px"
                        type="checkbox"
                        value=""
                        checked={!!selectedAvailableUsers.find(r => r === user.id)}
                        id={"user_available_check_" + user.id}
                        onChange={e => updateSelected(e, selectedAvailableUsers, setSelectedAvailableUsers, user.id)}/>
                      <label className={`form-check-label mw-100 text-break ${pendingDissociation.find(x => x === user.id) ? 'bg-danger bg-gradient rounded px-2' : ''}`} htmlFor={"user_available_check_" + user.id}>
                        { user.fullname } ({ user.username })
                      </label>
                    </div>
                  )
                })
              }
            </div>

            { users.filter(filterAvailable).length > 100 && <>Showing 100 of { users?.filter(filterAvailable).length } Users</> }
          </div>
          {/* END AVAILABLE USERS */}

          {/* BEGIN MANAGE BUTTONS */}
          <div className='col-12 col-lg-2 align-items-center'>
            <div className='d-flex flex-column justify-content-center h-100'>
              <Button
                type="button"
                variant="primary"
                size="sm"
                className="my-1"
                disabled={selectedAvailableUsers.length === 0}
                onClick={e => { associateSelectedUsers() }}>
                Associate Selected
              </Button>

              <Button
                type="button"
                variant="primary"
                size="sm"
                className="my-1 d-none d-lg-block"
                disabled={selectedAssociatedUsers.length === 0}
                onClick={e => { disassociateSelectedUsers() }}>
                Dissociate Selected
              </Button>
            </div>
          </div>
          {/* END MANAGE BUTTONS */}

          {/* BEGIN ASSOCIATED USERS */}
          <div className='col-12 col-lg-5 mt-3 mt-lg-0'>
            <div className='row'>
              {/* Header */}
              <div className='col-12 text-center mb-1'>
                <span className='h5'>Associated Users</span>
              </div>

              {/* Search Input */}
              <div className='col pe-0'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterAssociatedString}
                  onChange={e => setFilterAssociatedString(e.target.value)}
                />
              </div>

              {/* Clear Search Button */}
              <div className='col-auto'>
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-icon h-100"
                  disabled={filterAssociatedString === ''}
                  onClick={e => setFilterAssociatedString('')}>
                  <i className="bi bi-x-lg fs-4"></i>
                </Button>
              </div>
            </div>

            {/* List */}
            <div className='mh-200px mh-lg-400px overflow-scroll mt-2'>
              {
                users?.filter(filterAssociated).map(user => (
                  <div className="form-check pt-1" key={user.id}>
                    <input
                      className="form-check-input h-20px w-20px"
                      type="checkbox"
                      value=""
                      checked={!!selectedAssociatedUsers.find(r => r === user.id)}
                      id={"user_associated_check_" + user.id}
                      onChange={e => updateSelected(e, selectedAssociatedUsers, setSelectedAssociatedUsers, user.id)}/>
                    <label className={`form-check-label mw-100 text-break ${pendingAssociation.find(x => x === user.id) ? 'bg-success bg-gradient rounded px-2' : ''}`} htmlFor={"user_associated_check_" + user.id}>
                      { user.fullname } ({ user.username }) { user.companyID !== policy?.companyID ? ` (External)` : '' }
                    </label>
                  </div>
                ))
              }
            </div>
          </div>
          {/* END ASSOCIATED USERS */}

          {/* BEGIN DISSASOCIATE BUTTON */}
          <div className='col-12 d-lg-none align-items-center'>
            <div className='d-flex flex-column justify-content-center h-100'>
              <Button
                type="button"
                variant="primary"
                size="sm"
                className="my-1"
                disabled={selectedAssociatedUsers.length === 0}
                onClick={e => { disassociateSelectedUsers() }}>
                Dissociate Selected
              </Button>
            </div>
          </div>
          {/* END DISSASOCIATE BUTTON */}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="light"
          data-bs-dismiss="modal"
          onClick={modalHide}>
          Close
        </Button>
        
        <Button
          type="button"
          variant="primary"
          data-bs-dismiss="modal"
          disabled={[...pendingAssociation, ...pendingDissociation].length === 0}
          onClick={() => savePending()}>
          Save
        </Button>
      </Modal.Footer>
      {
        (isLoading || !modalTransitionCompleted) &&
        <div className="overlay-layer rounded bg-dark bg-opacity-50">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </Modal>
  )
}

export { ManageUsersModal }