import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Toast, ToastContainer, ProgressBar } from 'react-bootstrap'

interface ToastItem {
  id: string,
  message: string,
  title: string,
  type: ToastTypes,
  created: number,
  lifespan: number | null
}

export enum ToastTypes {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}

let setToastListOutside: Dispatch<SetStateAction<ToastItem[]>>
let toastListOutside: ToastItem[]
let count = 0

const ToastItemComponent: FC<{ toast: ToastItem }> = ({ toast }) => {
  const [ progress, setProgress ] = useState(0)
  const [ show, setShow ] = useState(true)

  const handleClose = () => {
    setShow(false)
    setTimeout(() => {
      RemoveToast(toast.id)
    }, 2000)
  }

  useEffect(() => {
    let p = 0
    let interval = 100

    let timer = setInterval(() => {
      if (!toast.lifespan) return

      p = p + (100 / (toast.lifespan / interval))
      
      if (p >= 100) {
        setProgress(100)
        clearInterval(timer)
        setTimeout(() => { handleClose() }, 500)
      } else {
        setProgress(p)
      }
    }, interval)
  }, [])

  return (
    <Toast bg={toast.type} show={show} onClose={() => { handleClose() }} key={toast.id} delay={toast.lifespan || -1}>
      <Toast.Header>
        <strong className="me-auto">{ toast.title }</strong>
      </Toast.Header>
      <Toast.Body>
        { toast.message }
      </Toast.Body>
      { toast.lifespan && <ProgressBar variant={toast.type} now={100 - progress} className='rounded-0 rounded-bottom' style={{backgroundColor: 'var(--bs-toast-header-bg)', height: '5px'}}/> }
    </Toast>
  )
}

export const ToastComponent: FC = () => {
  const [ toastList, setToastList ] = useState<ToastItem[]>([
    // {
    //   id: 'bs-toast-0',
    //   message: 'This is a test error',
    //   title: 'Test Error',
    //   type: ToastTypes.Danger,
    //   created: Date.now(),
    //   lifespan: 5000
    // }
  ])

  useEffect(() => {
    setToastListOutside = setToastList
  })

  useEffect(() => {
    toastListOutside = toastList
  })

  return (
    <ToastContainer containerPosition='fixed' position='bottom-end' className='m-4'>
      {
        toastList.map(toast => <ToastItemComponent toast={toast} key={toast.id}></ToastItemComponent>)
      }
    </ToastContainer>
  )
}

export function CreateToast(message: string, title: string, type: ToastTypes) {
  setToastListOutside([...toastListOutside, { id: 'bs-toast-' + count, message, title, type, created: Date.now(), lifespan: 5000 }])
  count++
}

export function RemoveToast(id: string) {
  setToastListOutside(toastListOutside.filter(t => t.id !== id))
}