import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useNavigate, useMatch } from 'react-router-dom'
import { deletePolicy, getReportPolicy } from '../core/_requests'
import { CreateToast, ToastTypes } from '../../plugins/Toasts'
import { Button, Modal } from 'react-bootstrap'
import { ReportPolicyModel } from '../core/_models'
import axios, { AxiosError } from 'axios'

const DeletePolicyModal: FC = () => {
  const navigate = useNavigate()
  const match = useMatch('/policy-admin/:id/delete')

  const [ visible, setVisible ] = useState(true)
  const [ modalTransitionCompleted, setTransitionCompleted ] = useState(false)
  const [ isLoading, setLoading ] = useState(false)
  
  const [ policy, setPolicy ] = useState<ReportPolicyModel | null>(null)
  const [ deletingPolicy, setDeletingPolicy ] = useState(false)

  const modalShow = async () => {
    // No error checking here since this component is conditionally rendered anyway, so will always match. We simply check to satisfy TypeScript.
    if (!match || !match.params.id) { return }

    setTimeout(() => {
      setTransitionCompleted(true)
    }, 300)

    setLoading(true)

    await getData()

    setLoading(false)
  }
  
  const modalHide = () => {
    setVisible(false)

    // Delay route change so we see the modal close animation
    setTimeout(() => {
      navigate('/policy-admin', { state: 'noscroll' })
    }, 150)
  }

  const getData = async () => {
    if (!match || !match.params.id) { return }

    getReportPolicy(match.params.id).then(response => {
      setPolicy(response.data)
    }).catch(error => {
      let message

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = 'Policy not found'
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }

      modalHide()
      CreateToast(message, 'Error', ToastTypes.Danger)
    })
  }

  const doDelete = async () => {
    if (!policy) return

    setDeletingPolicy(true)

    deletePolicy(policy.id).then(() => {
      CreateToast(`Deleted policy ${policy.name}`, 'Deleted Policy', ToastTypes.Success)
      modalHide()
    }).catch(error => {
      let message

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = axiosError.response.data
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }

      CreateToast(message, 'Error Deleting Policy', ToastTypes.Danger)
    }).finally(() => {
      setDeletingPolicy(false)
    })
  }
  
  return (
    <Modal size='lg' show={visible} onHide={modalHide} onShow={modalShow} className={ isLoading || !modalTransitionCompleted ? "overlay overlay-block position-fixed" : "" }>
      <Modal.Header closeButton>
        <Modal.Title>Delete Policy: { policy?.name }</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        Are you sure you want to delete this policy?
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="light"
          data-bs-dismiss="modal"
          disabled={deletingPolicy}
          onClick={modalHide}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="danger"
          data-bs-dismiss="modal"
          disabled={deletingPolicy}
          onClick={() => doDelete()}>
          Delete
        </Button>
      </Modal.Footer>
      {
        (isLoading || !modalTransitionCompleted) &&
        <div className="overlay-layer rounded bg-dark bg-opacity-50">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      }
    </Modal>
  )
}

export { DeletePolicyModal }