import React, { FC } from 'react'
import { Typeahead as TypeaheadBase } from 'react-bootstrap-typeahead'
import { TypeaheadComponentProps } from 'react-bootstrap-typeahead/types/components/Typeahead'
import TypeaheadModel from 'react-bootstrap-typeahead/types/core/Typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css'
import './style.css'
import { Field, FieldHookConfig, FieldInputProps, useField } from 'formik'
import { Option } from 'react-bootstrap-typeahead/types/types'

type InputFieldProps = FieldHookConfig<string> & {
  label: string
}

const Typeahead: FC<TypeaheadComponentProps & React.RefAttributes<TypeaheadModel>> = (props) => {
  // Copy `props.id` to `props.inputProps.id` (if `props.inputProps.id` isn't already set)
  let inputId = props.id + '-input'
  let newProps = {...props, inputProps: props.inputProps ? {...props.inputProps, id: props.inputProps.id || inputId} : { id: inputId }}

  // Add toggle button at end of input
  const ToggleButton: FC<{ isOpen: boolean, onClick: (e: any) => void }> = ({ isOpen, onClick }) => (
    <button
      type="button"
      className="typeahead-toggle-button"
      onClick={onClick}
      onMouseDown={(e) => { e.preventDefault() }}>
        { isOpen ? '▲' : '▼' }
    </button>
  )

  // // Add Formik support
  // const formikProps = { ...newProps.inputProps } as InputFieldProps
  // const [ field, meta, helper ] = useField(formikProps)

  // // Typeahead onChange event handler for Formik
  // newProps.onChange = (selected: Option[]) => {
  //   const value = selected.length > 0 ? selected[0].toString() : "";
  //   helper.setValue(value);
  //   console.log(value)
  //   // Call original onChange
  //   if (props.onChange) props.onChange(selected)
  // }

  // // Typeahead onInputChange event handler for Formik
  // newProps.onInputChange = (text: string, event: React.ChangeEvent<HTMLInputElement>) => {
  //   helper.setValue(text)

  //   // Call original onInputChange
  //   if (props.onInputChange) props.onInputChange(text, event)
  // }

  // // Typeahead onBlur event handler for Formik
  // newProps.onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
  //   helper.setTouched(true)

  //   // Call original onBlur
  //   if (props.onBlur) props.onBlur(event)
  // }

  return (
    <TypeaheadBase
      { ...newProps }
      // onInputChange={ (text) => {} }
      // { ...(meta.touched && meta.error
      //   ? { isInvalid: true, className: "is-invalid" }
      //   : { isValid: true})
      // }
    >
      {({ isMenuShown, toggleMenu }) => {
        // Show toggle button when isLoading set to false
        return (<>
          { !props.isLoading && <ToggleButton isOpen={isMenuShown} onClick={(e) => toggleMenu()} /> }
          
          {/* { meta.touched && meta.error ? (
            <div className="invalid-feedback">{meta.error}</div>
          ) : null } */}
        </>)
      }}
    </TypeaheadBase>
  )
}

export default Typeahead