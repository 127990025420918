import React, { ChangeEvent, useEffect, useState } from 'react'
import { PageTitle } from '../../../_metronic/layout/core'
import { CreateToast, ToastTypes } from '../../plugins/Toasts'
import axios, { AxiosError } from 'axios'
import Typeahead from '../../components/Typeahead'
import { Button, Form } from 'react-bootstrap'
import { LocationModel, UserModel } from '../core/_models'
import { getTestUrl, getUserLocations, getUsers, getUserSessionID } from '../core/_requests'

const TestReportsPage: React.FC = () => {
  const [ users, setUsers ] = useState<UserModel[]>([])
  const [ locations, setLocations ] = useState<LocationModel[]>([])
  const [ sessionID, setSessionID ] = useState<string>('')
  const [ testUrl, setTestUrl ] = useState<string>('')

  const [ selectedUser, setSelectedUser ] = useState<UserModel>()
  const [ selectedLocation, setSelectedLocation ] = useState<LocationModel>()

  const [ loadingUsers, setLoadingUsers ] = useState(false)
  const [ loadingSessionID, setLoadingSessionID ] = useState(false)
  const [ loadingLocations, setLoadingLocations ] = useState(false)
  const [ loadingUrl, setLoadingUrl ] = useState(false)

  useEffect(() => {
    getUsers().then(response => {
      let users = response.data.users
      setUsers(users)
    }).catch(error => {
      console.log(error)
    })
  }, [])

  const selectUser = (user: UserModel) => {
    setSelectedUser(user)

    if (!user) return

    setLoadingSessionID(true)
    setLoadingLocations(true)

    getUserSessionID(user.id).then(response => {
      setSessionID(response.data.sessionID)
    }).catch(error => {
      let message

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = axiosError.response.data
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }
        
      CreateToast(message, 'Error', ToastTypes.Danger)

      setSessionID('')
      console.log(error)
    }).finally(() => {
      setLoadingSessionID(false)
    })

    getUserLocations(user.id).then(response => {
      setLocations(response.data.locations)
    }).catch(error => {
      let message

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = axiosError.response.data
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }
        
      CreateToast(message, 'Error', ToastTypes.Danger)

      setLocations([])
      console.log(error)
    }).finally(() => {
      setLoadingLocations(false)
    })
  }

  useEffect(() => {
    if (!sessionID || !selectedLocation) return

    setLoadingUrl(true)

    getTestUrl(sessionID, selectedLocation.id).then(response => {
      setTestUrl(response.data.url)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setLoadingUrl(false)
    })
  }, [sessionID, selectedLocation])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Test Reports</PageTitle>

      <div className='card'>
        <div className='card-body'>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label htmlFor='typeahead-users-input'>Username</Form.Label>
              <Typeahead
                id="typeahead-users"
                options={users}
                labelKey={(option: any) => `${option.username}`}
                filterBy={[ 'username' ]}
                onChange={(selected) => { selectUser(selected[0] as UserModel || undefined) }}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='sessionId'>
              <Form.Label>Session ID{ selectedUser && ` (${selectedUser.username})` }</Form.Label>
              <Form.Control
                type='text'
                value={sessionID}
                onChange={e => setSessionID(e.target.value)}>
              </Form.Control>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label htmlFor='typeahead-location-input'>Location</Form.Label>
              <Typeahead
                id="typeahead-location"
                options={locations}
                labelKey={(option: any) => `${option.fullname}`}
                filterBy={[ 'fullname' ]}
                onChange={(selected) => { setSelectedLocation(selected[0] as LocationModel || undefined) }}
              />
            </Form.Group>

            <div className='row justify-content-end'>
              <div className='col-auto'>
                { !!selectedUser && !!sessionID && !!selectedLocation ?
                  <Button
                    variant='primary'
                    type='button'
                    href={ testUrl }
                    target='_blank'>
                    Go To User Reports
                  </Button>
                :
                  <Button
                    variant='primary'
                    type='button'
                    disabled={true}>
                    Go To User Reports
                  </Button>
                }
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export { TestReportsPage }