import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'

import { PolicyAdminPageWrapper } from '../pages/policy-admin/PolicyAdminPageWrapper'
import { ReportAdminPage } from '../pages/report-admin/ReportAdminPage'
import { TestReportsPage } from '../pages/test-reports/TestReportsPage'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Policy Admin page after successfull login */}
        <Route path='auth/*' element={<Navigate to='/policy-admin' />} />
        {/* Pages */}
        <Route path='policy-admin/*' element={<PolicyAdminPageWrapper />} />
        <Route path='report-admin' element={<ReportAdminPage />} />
        <Route path='test-reports' element={<TestReportsPage />} />
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
