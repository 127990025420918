/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pages</span>
        </div>
      </div>
      <AsideMenuItem
        to='/policy-admin'
        title='Policy Admin'
        fontIcon='bi-file-earmark-lock'
      />
      <AsideMenuItem
        to='/report-admin'
        title='Report Admin'
        fontIcon='bi-file-earmark-medical'
      />
      <AsideMenuItem
        to='/test-reports'
        title='Test User Reports'
        fontIcon='bi-people'
      />
    </>
  )
}
