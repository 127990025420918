import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { Outlet, useNavigate } from 'react-router-dom'
import { CompanyModel, CompanyTypeModel, GroupModel, ReportPolicyModel } from '../core/_models'
import { getCompanies, getCompanyTypes, getGroups, getReportPolicies, createPolicy } from '../core/_requests'
import { Button, Collapse, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Typeahead from '../../components/Typeahead'
import { CreateToast, ToastTypes } from '../../plugins/Toasts'

import './style.css'
import axios, { AxiosError } from 'axios'

const PolicyAdminPage: React.FC = () => {
  const navigate = useNavigate()

  const [ policies, setPolicies ] = useState<ReportPolicyModel[]>([])
  const [ isLoadingPolicies, setLoadingPolicies ] = useState(false)

  const [ companies, setCompanies ] = useState<CompanyModel[]>([])
  const [ isLoadingCompanies, setLoadingCompanies ] = useState(false)

  const [ companyTypes, setCompanyTypes ] = useState<CompanyTypeModel[]>([])
  const [ isLoadingCompanyTypes, setLoadingCompanyTypes ] = useState(false)

  const [ groups, setGroups ] = useState<GroupModel[]>([])
  const [ isLoadingGroups, setLoadingGroups ] = useState(false)

  const [ selectedCompanyType, setSelectedCompanyType ] = useState<CompanyTypeModel | null>(null)
  const [ selectedCompany, setSelectedCompany ] = useState<CompanyModel | null>(null)
  const [ selectedGroup, setSelectedGroup ] = useState<GroupModel | null>(null)

  const [ filterPolicies, setFilterPolicies ] = useState('')

  const [ isCreatingPolicy, setCreatingPolicy ] = useState(false)

  const filterSchema = Yup.object().shape({
    companyType: Yup.mixed().oneOf([...companyTypes]).required()
  })

  const filterInitialValues = {
    companyType: null
  }

  const formik = useFormik({
    initialValues: filterInitialValues,
    validationSchema: filterSchema,
    onSubmit: async (values) => {
      console.log(values)
    }
  })

  useEffect(() => {
    setLoadingPolicies(true);
    (async () => {
     const {data: response} = await getReportPolicies()
      setPolicies(response.reportPolicies)
      setLoadingPolicies(false)
    })();
    
    setLoadingCompanies(true);
    (async () => {
      const {data: response} = await getCompanies()
      setCompanies(response.companies)
      setLoadingCompanies(false)
    })();
    
    setLoadingCompanyTypes(true);
    (async () => {
      const {data: response} = await getCompanyTypes()
      setCompanyTypes(response.companyTypes)
      setLoadingCompanyTypes(false)
    })();
    
    setLoadingGroups(true);
    (async () => {
      const {data: response} = await getGroups()
      setGroups(response.groups)
      setLoadingGroups(false)
    })();
  }, [])

  const createPolicySubmit = async () => {
    if (!selectedCompany || !selectedGroup) {
      return
    }

    setCreatingPolicy(true)
    let policyName = selectedCompany.fullName + '_' + selectedGroup.groupName

    createPolicy(policyName, selectedCompany.id, selectedGroup.id).then(response => {
      let policy = response.data
      policies.unshift(policy)

      CreateToast(`Created policy ${policyName}`, 'Created Policy', ToastTypes.Success)
    }).catch(error => {
      let message

      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error

        if (axiosError.response) {
          message = axiosError.response.data
        } else {
          message = 'The server could not be reached'
        }
      } else {
        message = 'An unknown error occurred'
      }

      CreateToast(message, 'Error Creating Policy', ToastTypes.Danger)
    }).finally(() => {
      setCreatingPolicy(false)
    })
  }

  return (
    <>
      {/* Outlet for Modals */}
      <Outlet />

      <div className='card'>
        <div className='card-body'>

          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            spellCheck={false}>
            
            {!!formik.status &&
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            }

            <div className='row mb-10'>
              <div className='col-12 col-lg-10 pb-4 pb-lg-0'>
                <div className='row'>
                  <div className='col-md-4 mb-3 mb-md-0'>
                    <label className='form-label fs-6 text-dark' htmlFor='typeahead-companytype-input'>Company Type</label>
                    <Typeahead
                      id="typeahead-companytype"
                      options={companyTypes}
                      labelKey={(option: any) => `${option.fullName}`}
                      filterBy={[ 'fullName' ]}
                      placeholder="Choose a Company Type..."
                      onChange={(selected) => { setSelectedCompanyType(selected[0] as CompanyTypeModel || null) }}
                      isLoading={isLoadingCompanyTypes}
                    />
                    {/* {(formik.touched.companyType && formik.errors.companyType) && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.companyType}</span>
                        </div>
                      </div>
                    )} */}
                  </div>
                  
                  <div className='col-md-4 mb-3 mb-md-0'>
                    <label className='form-label fs-6 text-dark' htmlFor='typeahead-company-input'>Company</label>
                    <Typeahead
                      id="typeahead-company"
                      options={ selectedCompanyType ? companies.filter(c => c.types.indexOf(selectedCompanyType.id) > -1) : [] }
                      labelKey={(option: any) => `${option.fullName}`}
                      filterBy={[ 'fullName' ]}
                      placeholder="Choose a Company..."
                      onChange={(selected) => { setSelectedCompany(selected[0] as CompanyModel || null) }}
                      isLoading={isLoadingCompanies}
                    />
                  </div>
                  
                  <div className='col-md-4 mb-3 mb-md-0'>
                    <label className='form-label fs-6 text-dark' htmlFor='typeahead-group-input'>Group</label>
                    <Typeahead
                      id="typeahead-group"
                      options={groups}
                      labelKey={(option: any) => `${option.groupName}`}
                      filterBy={[ 'fullName' ]}
                      placeholder="Choose a Group..."
                      onChange={(selected) => { setSelectedGroup(selected[0] as GroupModel || null) }}
                      isLoading={isLoadingGroups}
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 col-lg-2'>
                <div className='row h-100 align-content-end gx-0'>
                  <Button
                    type="button"
                    variant="primary"
                    disabled={ (!selectedCompany || !selectedCompanyType || !selectedGroup) || isCreatingPolicy }
                    onClick={createPolicySubmit}>
                    Create Policy {isCreatingPolicy && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}
                  </Button>
                </div>
              </div>
            </div>
          </form>

          <div className='d-flex'>
            <div className='me-auto mw-450px row gx-0'>
              <div className='col me-2'>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  value={filterPolicies}
                  onChange={e => setFilterPolicies(e.target.value)}
                />
              </div>

              <div className='col-auto'>
                <Button
                  type="button"
                  variant="secondary"
                  className="btn-icon"
                  disabled={filterPolicies === ''}
                  onClick={e => setFilterPolicies('')}>
                  <i className="bi bi-x-lg fs-4"></i>
                </Button>
              </div>
            </div>

            <Button
              className='my-2 me-2'
              type='button'
              variant='secondary'
              size='sm'
              data-bs-toggle='collapse'
              data-bs-target={`.collapse.show`}
              aria-expanded='true'
              aria-controls={`accordion`}>
              Hide All
            </Button>

            <Button
              className='my-2'
              type='button'
              variant='secondary'
              size='sm'
              data-bs-toggle='collapse'
              data-bs-target={`.collapse:not(.show)`}
              aria-expanded='true'
              aria-controls={`accordion`}>
              Show All
            </Button>
          </div>

          <div className='accordion accordion-sm' id='policy_accordion'>
            {/* TODO: Move array conversion code to permanant object to increase readability and performance */}
            {
              policies.filter(x => x.name.toLowerCase().indexOf(filterPolicies.toLowerCase()) > -1).reduce((unique: any, o) => {
                let item = unique.find((obj: any) => obj.companyName === o.companyName)
                if (!item) {
                    unique.push({companyName: o.companyName, companyID: o.companyID , policies: [{ name: o.name, id: o.id }]})
                } else {
                    item.policies.push({ name: o.name, id: o.id })
                }
                return unique
              }, []).map((companyPolicies: any, index: number) => {
                return (
                  <div className='accordion-item' key={index}>
                    <h2 className='accordion-header' id={`policy_accordion_header_${index}`}>
                      <button
                        className='accordion-button fs-4 fw-bold'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#policy_accordion_body_${index}`}
                        aria-expanded='true'
                        aria-controls={`policy_accordion_body_${index}`}>
                        {companyPolicies.companyName}
                      </button>
                    </h2>
                    <div
                      id={`policy_accordion_body_${index}`}
                      className='accordion-collapse collapse show'
                      aria-labelledby={`policy_accordion_header_${index}`}
                      data-bs-parent={`policy_accordion_header_${index}`}>
                      <div className='accordion-body'>
                        <table>
                          <tbody>
                            {
                              companyPolicies.policies.map((policy: any) => (
                                <tr key={policy.id}>
                                  <td>
                                    <OverlayTrigger placement='bottom' overlay={
                                      <Tooltip id={`tooltip_report_${index}`}>
                                        Manage Reports
                                      </Tooltip>
                                    }>
                                      <button
                                        onClick={() => { navigate(`/policy-admin/${policy.id}/reports`, { state: 'noscroll' }) }}
                                        className="btn btn-icon btn-active-light-primary"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Tooltip on top">
                                        <i className="bi bi-file-earmark-medical fs-3"></i>
                                      </button>

                                    </OverlayTrigger>
                                    
                                    <OverlayTrigger placement='bottom' overlay={
                                      <Tooltip id={`tooltip_users_${index}`}>
                                        Manage Users
                                      </Tooltip>
                                    }>
                                      <button
                                        onClick={() => { navigate(`/policy-admin/${policy.id}/users`, { state: 'noscroll' }) }}
                                        className="btn btn-icon btn-active-light-primary"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Tooltip on top">
                                        <i className="bi bi-person fs-3"></i>
                                      </button>
                                    </OverlayTrigger>
                                    
                                    <OverlayTrigger placement='bottom' overlay={
                                      <Tooltip id={`tooltip_delete_${index}`}>
                                        Delete Policy
                                      </Tooltip>
                                    }>
                                      <button
                                        onClick={() => { navigate(`/policy-admin/${policy.id}/delete`, { state: 'noscroll' }) }}
                                        className="btn btn-icon btn-active-light-danger me-5"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        data-bs-title="Tooltip on top">
                                        <i className="bi bi-trash fs-3"></i>
                                      </button>
                                    </OverlayTrigger>
                                  </td>
                                  <td>{policy.name}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export { PolicyAdminPage }